// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';

@import '~bootstrap/scss/bootstrap';

body.vsc-initialized,
input,
button {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.divMainLogin {
  margin-top: -2em;
  margin-left: -30px;
  margin-right: -30px;
  background-color: #214270;
}

.imgLogo {
  width: 250px;
}

.c-sidebar .c-sidebar-brand {
  background-color: #fff;
}

.c-header {
  background-color: #214270;
  border-bottom: none;
}

.c-header .c-header-toggler-icon {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwyNTUsMjU1LDAuNzUpJyBzdHJva2Utd2lkdGg9JzIuMjUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==);
}

.c-header .c-header-toggler-icon:hover {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwyNTUsMjU1LDAuOSknIHN0cm9rZS13aWR0aD0nMi4yNScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+);
}

.c-header .c-header-nav .c-header-nav-link,
.c-header .c-header-nav .c-header-nav-btn {
  color: hsla(0, 0%, 100%, 0.75);
}

.c-header .c-header-nav .c-header-nav-link:hover,
.c-header .c-header-nav .c-header-nav-link:focus,
.c-header .c-header-nav .c-header-nav-btn:hover,
.c-header .c-header-nav .c-header-nav-btn:focus {
  color: hsla(0, 0%, 100%, 0.9);
}

.c-header .c-header-nav .c-header-nav-link:hover,
.c-header .c-header-nav .c-header-nav-link:focus,
.c-header .c-header-nav .c-header-nav-btn:hover,
.c-header .c-header-nav .c-header-nav-btn:focus {
  color: hsla(0, 0%, 100%, 0.9);
}

.c-sidebar {
  background-color: #214270;
}

.wave {
  background-image: url('wave.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -32px;
}

.container-fluid-wave {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 32px;
}

.pre-wrap {
  white-space: pre-wrap;
}

/** Perfil profissional */
.perfilProfissional .dadosBasicos {
  display: flex;
  justify-content: flex-start;
}
.perfilProfissional .dadosBasicos img {
  width: 160px;
}

.perfilProfissional .nome {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.selectProdutos-profissional {
  max-width: 380px;
}

.price-tag {
  display: inline-block;

  width: auto;
  height: 38px;

  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;

  border-left: 1px solid #979797;

  /* This makes room for the triangle */
  margin-left: 19px;

  position: relative;

  color: white;
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 22px;
  line-height: 38px;

  padding: 0 10px 0 10px;
}

/* Makes the triangle */
.price-tag:before {
  content: '';
  position: absolute;
  display: block;
  left: -19px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid inherit;
}

/* Makes the circle */
.price-tag:after {
  content: '';
  background-color: transparent;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: -9px;
  top: 17px;
}

.bg-instituicao {
  background-color: #214270 !important;
  border-color: #214270 !important;
  color: #fff !important;
}

.bg-disabled {
  background-color: #e9ecef !important;
  color: #6c757d !important;
}

.width130-txt-right {
  width: 110px;
  display: inline-block;
  padding-right: 10px;
  font-weight: bold;
}

.dialogo-assinatura{
  background-color: #081d2e !important;
  border-color: #081d2e !important;
  color: #fff !important;
}

.button-assinatura-confirm:disabled {
  background-color: grey !important;
  border-color: grey !important;
  cursor: not-allowed;
  opacity: 0.6;
  color: white; 
}

.button-assinatura-confirm {
  background-color: #03a11d !important;
  border-color: #03a11d !important;
}

.button-assinatura-confirm:not(:disabled):hover {
  background-color: #04881a !important;
}

.button-assinatura-cancel {
  background-color: #ed0101 !important;
  border-color: #ed0101 !important;
}

.button-assinatura-cancel:hover {
  background-color: #c90808 !important;
  ;
}
