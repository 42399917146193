.form-label.required:after {
  content: ' *';
  color: #880000;
}

// trick para que o botao do modal nao cancele a animacao de fechamento do modal
.modal-footer .btn {
  transition: none;
}

.modal-danger .modal-content {
  background-color: $danger-25;
}
.modal-warning .modal-content {
  background-color: $warning-25;
}

.valid-feedback,
.invalid-feedback {
  &.force-feedback {
    display: block;
  }
}

.foto {
  // Uma edição de foto pode arrastar todo o conteúdo base64 para um campo de input habilitado o.O
  // Essa propriedade desliga esse comportamento
  -webkit-user-drag: none;
}

.img-avatar {
  width: 160px !important;
  /*height: 160px !important;*/
  border-radius: 50%;
  object-fit: cover;
}

.texto-normal-badge {
  font-size: 1rem !important;
}
